import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import "./index.css"
import HeaderMain from "../components/headerMain"

const Title = styled.p`
  font-family: "AmazonEmberMedium";
  margin-top: 20px;
  margin-bottom: 20px;
  height: 22px;
  left: 0%;
  right: 0%;
  top: calc(50% - 24px / 2 + 0.5px);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #3d3c3c;
`

const BodyLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const Body = styled.div`
  border-bottom: 0.5px solid #cfcfcf;
  &:last-child {
    border-bottom: none;
  }
`
enum Categories {
  General = "General",
  GettingStarted = "Getting Started",
  HowItWorks = "How it works",
  UsingtheApp = "Using the App",
  Testing = "Testing",
  TravelingWithEmblem = "Traveling with Emblem",
  Telehealth = "Telehealth",
  DataAndPrivacy = "Data & Privacy",
  Troubleshooting = "Troubleshooting",
}

const categories = [
  Categories.General,
  Categories.GettingStarted,
  Categories.HowItWorks,
  Categories.UsingtheApp,
  Categories.Testing,
  Categories.TravelingWithEmblem,
  Categories.Telehealth,
  Categories.DataAndPrivacy,
  Categories.Troubleshooting,
]

function Category({ data }): JSX.Element {
  const title = "FAQs & help"
  return (
    <Layout>
      <HeaderMain siteTitle={title} />
      <div className="container1">
        <div className="container2">
          {categories.map((category, index) => (
            <Body key={index}>
              <BodyLink to={`/${category}`}>
                <Title>{category}</Title>
              </BodyLink>
            </Body>
          ))}
        </div>
      </div>
    </Layout>
  )
}
export default Category
