import * as React from "react"
import { Link } from "gatsby"
import { ChevronLeft } from "../Resources/svg/chevronLeft"
import "../css/header.css"

interface HeaderMainProps {
  siteTitle: string
}

const HeaderMain = ({ siteTitle }: HeaderMainProps) => (
  <div className="header-container">
    <header className="head-container">
      <Link to={`/error`}>
        <button
          style={{
            border: "none",
            backgroundColor: "transparent",
            position: "absolute",
            left: 20,
            top: 44,
          }}
        >
          <ChevronLeft />
        </button>
      </Link>
      <p className="siteHeader">{siteTitle}</p>
    </header>
  </div>
)

export default HeaderMain
